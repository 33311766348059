import { RootStore } from '../StoreManager';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { Layout, Layouts } from 'react-grid-layout';
import { ApiStore } from '../Global/ApiStore';
import { EmployeesMarginalityStore } from '../EmployeesMarginalityStore';
import { RatingOfManagersStore } from '../RatingOfManagersStore';
import { AuthStore } from '../AuthStore';
import { UserOmniGetResponse } from '../../api/marketx';
import { AppUserGroupCodes } from '../../types/AppUserGroup';
import { INDICATORS_ROLE_EMPLOYEE_CODES, IndicatorsRoleEmployee } from '../IndicatorsPanelStore';

const lsKey = 'home-grid-layout-v5';

export const layoutsNames = {
  '0': 'donatCount', // donatCount - Количество
  '1': 'donatPlan', // donatPlan - План
  '2': 'donatProfit', // donatProfit - МаржДоход
  '3': 'donatWorkEvaluation', // donatWorkEvaluation - Оценка работы
  '4': 'salesFunnel', // salesFunnel - Воронка продаж
  '5': 'champions', // champions - Чемпионы
  '6': 'paymentSchedule', // paymentSchedule - График платежей
  '7': 'reserves', // reserves - Резерв
  '8': 'kanbanBoard', // kanbanBoard - Доска задач
};

const defaultLayoutItemLg: Layout[] = [
  /* I строка */
  { i: '0', x: 0, y: 0, w: 4, h: 4, minW: 4, minH: 4 }, // donatCount - Количество
  { i: '1', x: 4, y: 0, w: 4, h: 4, minW: 4, minH: 4 }, // donatPlan - План
  { i: '4', x: 8, y: 0, w: 8, h: 8, minW: 6, minH: 8 }, // salesFunnel - Воронка продаж
  { i: '5', x: 16, y: 0, w: 8, h: 8, minW: 6, minH: 6 }, // champions - Чемпионы
  /* II строка */
  { i: '2', x: 0, y: 4, w: 4, h: 4, minW: 4, minH: 4 }, // donatProfit - МаржДоход
  { i: '3', x: 4, y: 4, w: 4, h: 4, minW: 4, minH: 4 }, // donatWorkEvaluation - Оценка работы
  /* III строка */
  { i: '6', x: 0, y: 8, w: 6, h: 5, minW: 5, minH: 4 }, // paymentSchedule - График платежей
  { i: '7', x: 6, y: 8, w: 8, h: 5, minW: 6, minH: 5 }, // reserves - Резерв
  { i: '8', x: 14, y: 8, w: 10, h: 5, minW: 8, minH: 5 }, // kanbanBoard - Доска задач
];

const defaultLayoutItemMd: Layout[] = [
  /* I строка */
  { i: '0', x: 0, y: 0, w: 4, h: 4, minW: 4, minH: 4 }, // donatCount - Количество
  { i: '1', x: 4, y: 0, w: 4, h: 4, minW: 4, minH: 4 }, // donatPlan - План
  { i: '4', x: 8, y: 0, w: 6, h: 8, minW: 6, minH: 8 }, // salesFunnel - Воронка продаж
  { i: '5', x: 14, y: 0, w: 6, h: 8, minW: 6, minH: 8 }, // champions - Чемпионы
  /* II строка */
  { i: '2', x: 0, y: 4, w: 4, h: 4, minW: 4, minH: 4 }, // donatProfit - МаржДоход
  { i: '3', x: 4, y: 4, w: 4, h: 4, minW: 4, minH: 4 }, // donatWorkEvaluation - Оценка работы
  /* III строка */
  { i: '6', x: 0, y: 8, w: 8, h: 4, minW: 6, minH: 4 }, // paymentSchedule - График платежей
  { i: '7', x: 0, y: 12, w: 8, h: 5, minW: 8, minH: 5 }, // reserves - Резерв
  { i: '8', x: 8, y: 8, w: 12, h: 6, minW: 9, minH: 6 }, // kanbanBoard - Доска задач
];

const defaultLayoutItemSm: Layout[] = [
  /* I строка */
  { i: '0', x: 0, y: 0, w: 4, h: 4, minW: 4, minH: 4 }, // donatCount - Количество
  { i: '1', x: 4, y: 0, w: 4, h: 4, minW: 4, minH: 4 }, // donatPlan - План
  { i: '4', x: 0, y: 8, w: 6, h: 8, minW: 5, minH: 8 }, // salesFunnel - Воронка продаж
  { i: '5', x: 6, y: 8, w: 6, h: 8, minW: 5, minH: 4 }, // champions - Чемпионы
  /* II строка */
  { i: '2', x: 8, y: 0, w: 4, h: 4, minW: 4, minH: 4 }, // donatProfit - МаржДоход
  { i: '3', x: 0, y: 4, w: 4, h: 4, minW: 4, minH: 4 }, // donatWorkEvaluation - Оценка работы
  /* III строка */
  { i: '6', x: 4, y: 4, w: 8, h: 4, minW: 5, minH: 4 }, // paymentSchedule - График платежей
  { i: '7', x: 0, y: 16, w: 12, h: 5, minW: 7, minH: 5 }, // reserves - Резерв
  { i: '8', x: 0, y: 21, w: 12, h: 4, minW: 7, minH: 4 }, // kanbanBoard - Доска задач
];

const defaultLayoutItemXs: Layout[] = [
  /* I строка */
  { i: '0', x: 0, y: 0, w: 4, h: 4, minW: 4, minH: 4 }, // donatCount - Количество
  { i: '1', x: 4, y: 0, w: 4, h: 4, minW: 4, minH: 4 }, // donatPlan - План
  { i: '4', x: 0, y: 8, w: 8, h: 8, minW: 4, minH: 8 }, // salesFunnel - Воронка продаж
  { i: '5', x: 0, y: 16, w: 8, h: 8, minW: 4, minH: 4 }, // champions - Чемпионы
  /* II строка */
  { i: '2', x: 0, y: 4, w: 4, h: 4, minW: 4, minH: 4 }, // donatProfit - МаржДоход
  { i: '3', x: 4, y: 4, w: 4, h: 4, minW: 4, minH: 4 }, // donatWorkEvaluation - Оценка работы
  /* III строка */
  { i: '6', x: 0, y: 24, w: 8, h: 4, minW: 4, minH: 4 }, // paymentSchedule - График платежей
  { i: '7', x: 0, y: 28, w: 8, h: 5, minW: 4, minH: 5 }, // reserves - Резерв
  { i: '8', x: 0, y: 33, w: 8, h: 5, minW: 6, minH: 5 }, // kanbanBoard - Доска задач
];

const defaultLayoutItemXxs: Layout[] = [
  /* I строка */
  { i: '0', x: 0, y: 0, w: 4, h: 4, minW: 4, minH: 4 }, // donatCount - Количество
  { i: '1', x: 0, y: 8, w: 4, h: 4, minW: 4, minH: 4 }, // donatPlan - План
  { i: '4', x: 0, y: 20, w: 4, h: 8, minW: 4, minH: 8 }, // salesFunnel - Воронка продаж
  { i: '5', x: 0, y: 28, w: 4, h: 8, minW: 4, minH: 4 }, // champions - Чемпионы
  /* II строка */
  { i: '2', x: 0, y: 12, w: 4, h: 4, minW: 4, minH: 4 }, // donatProfit - МаржДоход
  { i: '3', x: 0, y: 16, w: 4, h: 4, minW: 4, minH: 4 }, // donatWorkEvaluation - Оценка работы
  /* III строка */
  { i: '6', x: 0, y: 36, w: 4, h: 4, minW: 4, minH: 4 }, // paymentSchedule - График платежей
  { i: '7', x: 0, y: 40, w: 4, h: 6, minW: 4, minH: 6 }, // reserves - Резерв
  { i: '8', x: 0, y: 46, w: 4, h: 7, minW: 4, minH: 7 }, // kanbanBoard - Доска задач
];

const defaultLayouts: ReactGridLayout.Layouts = {
  lg: [...defaultLayoutItemLg],
  md: [...defaultLayoutItemMd],
  sm: [...defaultLayoutItemSm],
  xs: [...defaultLayoutItemXs],
  xxs: [...defaultLayoutItemXxs],
};

export class HomeGridStore {
  role: IndicatorsRoleEmployee;
  layoutKey = '';
  authStore: AuthStore;
  apiStore: ApiStore;
  employeesMarginality: EmployeesMarginalityStore;
  ratingOfManagersStore: RatingOfManagersStore;
  layouts: ReactGridLayout.Layouts = {
    lg: [],
    md: [],
    sm: [],
    xs: [],
    xxs: [],
  };

  isEditing = false;

  isLoading = false;
  isLoaded = false;
  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.authStore = rootStore.getAuth();
    this.layoutKey = lsKey;
    this.role = this.getTypeByRole();
    makeAutoObservable(this);
  }

  getTypeByRole(): IndicatorsRoleEmployee {
    const isDivisionChief = this.authStore.inGroup(AppUserGroupCodes.DIVISION_CHIEF);
    const isDivisionMarketer = this.authStore.inGroup(AppUserGroupCodes.DIVISION_MARKETER);
    const isOfficeChief = this.authStore.inGroup(AppUserGroupCodes.OFFICE_CHIEF);
    const isOfficeMarketer = this.authStore.inGroup(AppUserGroupCodes.OFFICE_MARKETER);
    const isSellerChief = this.authStore.inGroup(AppUserGroupCodes.SELLER_CHIEF);
    const isCompanyChief = this.authStore.inGroup(AppUserGroupCodes.COMPANY_CHIEF);
    const isCompanyMarketer = this.authStore.inGroup(AppUserGroupCodes.COMPANY_MARKETER);
    const isSupport = this.authStore.inGroup(AppUserGroupCodes.SUPPORT);

    if (isCompanyChief || isCompanyMarketer) {
      return INDICATORS_ROLE_EMPLOYEE_CODES.company;
    }
    if (isDivisionChief || isDivisionMarketer) {
      return INDICATORS_ROLE_EMPLOYEE_CODES.division;
    }
    if (isOfficeChief || isSupport || isOfficeMarketer) {
      return INDICATORS_ROLE_EMPLOYEE_CODES.office;
    }
    if (isSellerChief) {
      return INDICATORS_ROLE_EMPLOYEE_CODES.department;
    }
    INDICATORS_ROLE_EMPLOYEE_CODES;
    return INDICATORS_ROLE_EMPLOYEE_CODES.employee;
  }

  get structureName(): string {
    const { employee, branchOfficeName, chosenBranchOfficeDivisionTitle } = this.authStore.profile;
    switch (this.role) {
      case 'company':
        return 'МКМ';
      case 'division':
        return chosenBranchOfficeDivisionTitle;
      case 'office':
        return branchOfficeName;
      case 'department':
        return employee.departmentTitle;
      case 'employee':
        return `${employee.surname || ''} ${employee.name || ''} ${employee.patronymic || ''}`;
      default:
        return '';
    }
  }

  getUserLayouts(): void {
    this.apiStore
      .apiClientUser()
      .userOmniGet([this.layoutKey])
      .then(res => {
        this.saveUserLayouts(res.data);
      })
      .catch(e => {
        console.warn('getUserLayoutsRequest', e);
      });
  }

  saveUserLayouts(data: UserOmniGetResponse): void {
    const savedLayout = data.entries.filter(e => e.key === this.layoutKey)?.[0];
    if (savedLayout?.value) {
      const parsedLayouts = JSON.parse(savedLayout.value) as ReactGridLayout.Layouts;
      Object.keys(parsedLayouts).forEach(name => {
        if (this.layouts[name]) {
          this.layouts = { ...this.layouts, [name]: parsedLayouts[name] };
        }
      });
    } else {
      this.layouts = { ...defaultLayouts };
    }
    runInAction(() => {
      this.isLoading = false;
      this.isLoaded = true;
    });
  }

  setEdit(v: boolean): void {
    runInAction(() => {
      this.isEditing = v;
    });
  }

  setUserLayouts(layouts: Layouts): void {
    if (!this.isLoaded) {
      return;
    }
    const value = JSON.stringify(toJS(layouts));
    this.apiStore.apiClientUser().userOmniSet({
      entries: [
        {
          key: this.layoutKey,
          value,
        },
      ],
    });
  }

  onLayoutChange(layouts: Layouts): void {
    this.layouts = layouts;
    this.setUserLayouts(this.layouts);
  }

  addWidget(id: string, breakpoint: string): void {
    Object.keys(this.layouts).forEach(l => {
      if (l === breakpoint) {
        const newWidget = defaultLayouts[l].filter(item => item.i === id)[0];
        this.layouts = {
          ...this.layouts,
          [l]: [...this.layouts[l], newWidget],
        };
      }
    });
    this.setUserLayouts(toJS(this.layouts));
  }

  addAllWidgets(breakpoint: string): void {
    Object.keys(this.layouts).forEach(l => {
      if (l === breakpoint) {
        const newWidgets = defaultLayouts[l].filter(i => !this.layouts[l].some(w => w.i === i.i));
        this.layouts = {
          ...this.layouts,
          [l]: [...this.layouts[l], ...newWidgets],
        };
      }
    });
    this.setUserLayouts(toJS(this.layouts));
  }

  removeWidget(breakpoint: string, id: string): void {
    Object.keys(this.layouts).forEach(l => {
      if (l === breakpoint) {
        this.layouts = {
          ...this.layouts,
          [l]: this.layouts[l].filter(i => i.i !== id),
        };
      }
    });
    this.setUserLayouts(toJS(this.layouts));
  }

  removeAllWidgets(breakpoint: string): void {
    Object.keys(this.layouts).forEach(l => {
      if (l === breakpoint) {
        this.layouts = {
          ...this.layouts,
          [l]: [],
        };
      }
    });
    this.setUserLayouts(toJS(this.layouts));
  }
}

// save to localStorage
// const lsVersion = 'rgl-v4';
//
// function getFromLS(key): any {
//   let ls = {};
//   if (global.localStorage) {
//     try {
//       ls = JSON.parse(global.localStorage.getItem(lsVersion)) || {};
//     } catch (e) {
//       return undefined;
//     }
//   }
//   return ls[key];
// }
//
// function saveToLS(key, value): void {
//   if (global.localStorage) {
//     this.apiStore.apiClientUser().userOmniSet(
//       lsVersion,
//       JSON.stringify({
//         [key]: value,
//       })
//     );
//   }
// }
