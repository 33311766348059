import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link as MxLink } from '@mx-ui/ui';
import Typography from '@mui/material/Typography';
import { EmployeesMarginalityStore } from '../../../store/EmployeesMarginalityStore';
import { EmployeeMarginalityReport } from '../../../api/marketx';
import { roundNumber } from '@mx-ui/helpers';
import PieChartIcon from '../PieChartIcon';

interface WorkEvaluationChartProps {
  employeesMarginality: EmployeesMarginalityStore;
  budgetGroupsStats: EmployeeMarginalityReport;
  isGridEditing: boolean;
}

export const WorkEvaluationChart: FC<WorkEvaluationChartProps> = observer(
  ({ employeesMarginality, budgetGroupsStats: stats, isGridEditing }): JSX.Element => {
    const { report, motivationReport } = stats || {};
    const { totalCompletionPct, marginCompletionFinalPct, quantityCompletionFinalPct } = motivationReport ?? {};
    const { overallWeight } = report || {};
    const { margin: overallWeightMargin, quantity: overallWeightQuantity } = overallWeight || {};
    const motivationTotalCompletionPercent = typeof totalCompletionPct === 'number' ? Math.round(totalCompletionPct) : undefined;

    return (
      <Paper
        sx={{
          padding: 1,
          height: '100%',
          width: '100%',
        }}
      >
        <Box sx={{ textAlign: 'left' }}>
          <MxLink
            href={isGridEditing ? undefined : `/app/report/targets?${'mode=' + employeesMarginality.mode}`}
            variant="h6"
            color="inherit"
            typographySx={{ cursor: isGridEditing ? 'initial' : 'pointer' }}
          >
            Оценка работы
          </MxLink>
        </Box>
        <Grid container alignItems="center" justifyContent="center" flexDirection="column">
          <Grid item sx={{ paddingTop: 2 }}>
            <Typography sx={{ marginLeft: '4px', fontSize: '40px' }} variant="body1" gutterBottom color="#66BB6A" fontSize="28px">
              {roundNumber(motivationTotalCompletionPercent)}%
            </Typography>
          </Grid>
          <Grid item container>
            <Grid item container justifyContent="space-between" alignItems="flex-start" mb={1}>
              <span>Кол-во</span>
              <Box display="flex" justifyContent="flex-end" flex="0 1 50%">
                <span title="Оценка (Количество)" style={{ fontWeight: 'bold', paddingRight: '15px ' }}>
                  {quantityCompletionFinalPct ? Math.round(quantityCompletionFinalPct) : 0}%
                </span>
                <PieChartIcon width="25px" height="24px" />
                <span title="Вес (Количество)" style={{ paddingLeft: '3px' }}>
                  {overallWeightQuantity ? Math.round(100 * overallWeightQuantity) : 0}%
                </span>
              </Box>
            </Grid>
            <Grid item container justifyContent="space-between" alignItems="center">
              <span>МД</span>
              <Box display="flex" justifyContent="flex-end" flex="0 1 50%">
                <span title="Оценка (маржинальный доход)" style={{ fontWeight: 'bold', paddingRight: '15px' }}>
                  {marginCompletionFinalPct ? Math.round(marginCompletionFinalPct) : 0}%
                </span>
                <PieChartIcon width="25px" height="25px" />
                <span title="Вес (маржинальный доход)" style={{ paddingLeft: '3px' }}>
                  {overallWeightMargin ? Math.round(100 * overallWeightMargin) : 0}%
                </span>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
);
