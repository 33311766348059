import React, { FC, JSX, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link as MxLink, MxSlider } from '@mx-ui/ui';
import Typography from '@mui/material/Typography';
import { DoughnutChart } from '../../charts/DoughnutChart';
import CircleIcon from '@mui/icons-material/Circle';
import { EmployeesMarginalityStore } from '../../../store/EmployeesMarginalityStore';
import { EmployeeMarginalityReport } from '../../../api/marketx';
import { colorsByValue, formatValueTon } from '../ChartsBlock';
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

interface CountDoughnutProps {
  employeesMarginality: EmployeesMarginalityStore;
  budgetGroupsStats: EmployeeMarginalityReport;
  isGridEditing: boolean;
}

export const CountDoughnut: FC<CountDoughnutProps> = observer(
  ({ employeesMarginality, budgetGroupsStats: stats, isGridEditing }): JSX.Element => {
    const { report, motivationReport } = stats || {};
    const { quantityForecast, overallWeight } = report || {};
    const { dynamicScaleThresholds, quantityCompletionPct, quantityCompletionScore, quantityCompletionFinalPct } = motivationReport ?? {};
    const marks = useMemo(() => dynamicScaleThresholds?.map(i => ({ value: i, label: String(i) })), [dynamicScaleThresholds]);
    const quantityAchievementPercent = Math.round(report?.quantityAchievement ?? 0);
    const { level: quantityForecastLevel, value: quantityForecastValue } = quantityForecast || {};
    const quantityCompletionPctV = Math.round(quantityCompletionPct ?? 0);
    const gradientColorAndValForQuantity = useMemo(() => colorsByValue(quantityCompletionPctV), [quantityCompletionPctV]);
    const quantityCompletionScoreV = Math.round(quantityCompletionScore ?? 0);
    const { quantity: overallWeightQuantity } = overallWeight || {};
    return (
      <Paper
        sx={{
          padding: 1,
          height: '100%',
          width: '100%',
        }}
      >
        <Grid container height={'100%'}>
          <Grid item xs={12} height={'32px'}>
            <Box sx={{ textAlign: 'left', height: '24px', p: 0 }}>
              <MxLink
                href={isGridEditing ? undefined : `/app/report/targets?${'mode=' + employeesMarginality.mode}`}
                variant="h6"
                color="inherit"
                typographySx={{ cursor: isGridEditing ? 'initial' : 'pointer' }}
              >
                Количество
              </MxLink>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            height={'calc(100% - 32px)'}
            sx={{
              '& .swiper-pagination-bullet': { background: theme => (theme.palette.mode === 'dark' ? '#b7b7b7' : '#020202') },
              '& .swiper-pagination-bullet-active': { background: theme => (theme.palette.mode === 'dark' ? '#a8a6a6' : '#080f72') },
              '& .swiper-pagination': { display: 'flex', justifyContent: 'flex-end' },
            }}
          >
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              loop={false}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              modules={[Pagination]}
              className="mySwiper"
              style={{ height: '100%' }}
            >
              <SwiperSlide style={{ height: '100%', paddingTop: '8px' }}>
                <Box
                  sx={{
                    position: 'relative',
                    margin: '0 auto 8px',
                    maxWidth: '104px',
                    height: '104px',
                    maxHeight: '104px',
                  }}
                >
                  <DoughnutChart value={!employeesMarginality.isLoading ? quantityAchievementPercent : 0} />
                </Box>
                <Grid container justifyContent="center">
                  {quantityForecastLevel && <CircleIcon sx={{ color: quantityForecastLevel }} />}
                  <Typography sx={{ marginLeft: '4px' }} variant="body1" gutterBottom>
                    Тренд {quantityForecastValue ? Math.round(quantityForecastValue) : 0}%
                  </Typography>
                </Grid>
              </SwiperSlide>
              <SwiperSlide style={{ height: '100%' }}>
                <Box>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant="body2">План</Typography>
                    </Grid>
                    <Grid item xs={8} textAlign="right" color={report?.planQuantity < 0 ? 'red' : undefined}>
                      <Typography variant="body2" noWrap>
                        {formatValueTon(report?.planQuantity)}&nbsp;т
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2">Факт</Typography>
                    </Grid>
                    <Grid item xs={8} textAlign="right" color={report?.planQuantity < 0 ? 'red' : undefined}>
                      <Typography variant="body2" noWrap>
                        {formatValueTon(report?.factQuantity)}&nbsp;т
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box borderBottom={'1px solid rgba(224,224,224,1)'} mt={0.5} mb={1} />
                  <Grid container flexDirection="column">
                    <Box sx={{ marginLeft: 1 / 2, marginRight: 1 / 2, paddingTop: 1.5, paddingBottom: 1 }} display="flex">
                      {motivationReport && (
                        <MxSlider
                          value={quantityCompletionPctV}
                          disabled
                          // valueLabelDisplay="on"
                          valueLabelFormat={() => `${quantityCompletionPctV}%`}
                          gradientColorAndVal={gradientColorAndValForQuantity}
                          marks={marks}
                          min={0}
                          max={150}
                        />
                      )}
                      {motivationReport && (
                        <Typography variant="body2" sx={{ marginLeft: 0.5, paddingTop: 1 }}>{`${quantityCompletionScoreV}%`}</Typography>
                      )}
                    </Box>

                    <Grid
                      container
                      justifyContent={motivationReport ? 'space-between' : 'flex-end'}
                      sx={{ marginLeft: 0.5, paddingRight: 0.5 }}
                    >
                      {motivationReport && (
                        <Typography variant="body1">
                          Оценка {quantityCompletionFinalPct ? Math.round(quantityCompletionFinalPct) : 0}%
                        </Typography>
                      )}
                      <Typography variant="body1">Вес {overallWeightQuantity ? Math.round(100 * overallWeightQuantity) : 0}%</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </SwiperSlide>
            </Swiper>
          </Grid>
        </Grid>
      </Paper>
    );
  }
);
