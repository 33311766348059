import React, { useEffect } from 'react';
import type { FC } from 'react';
import StarsIcon from '@mui/icons-material/Stars';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { StatMarginalityEmployeeOverallRatingItem } from 'src/api/marketx';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { UserAvatar } from '@mx-ui/ui';

const colorByRatingPosition = {
  1: '#ffd700',
  2: '#c0c0c0',
  3: '#cd7f32',
};

interface ListRatingProps {
  data: StatMarginalityEmployeeOverallRatingItem[];
  currentUserCode: string;
  onShow?: () => void;
  isLoaded: boolean;
  isShowAll?: boolean;
  isScrollIntoView?: boolean;
}

export const ListRating: FC<ListRatingProps> = ({
  data = [],
  isLoaded = true,
  currentUserCode = '',
  isShowAll = false,
  isScrollIntoView = false,
  onShow,
}): JSX.Element => {
  const showNumberUsers = 5;
  let ratingItems: StatMarginalityEmployeeOverallRatingItem[];
  if (isShowAll) {
    ratingItems = data;
  } else {
    const start = 0;
    const end = data.length;
    const currentUserIndex = data.findIndex(i => i.employee.code === currentUserCode);
    if (currentUserIndex !== -1) {
      // рейтинг топ 3 (золото серебро бронза) ... и остальные 6 мест(7-ое это "..."), где текущий юзер в середине
      if (currentUserIndex <= showNumberUsers - 1) {
        ratingItems = data.slice(start, showNumberUsers);
      } else {
        const showUsersFirstCount = 2;
        const showUsersNexToCurrentUserCount = 3; //* не кратное двум

        // showUsersNexToCurrentUserCount-1/2 + currentUserIndex + showUsersNexToCurrentUserCount-1/2;

        const usersFirst = data.slice(start, showUsersFirstCount);
        let startRest = currentUserIndex - (showUsersNexToCurrentUserCount - 1) / 2; // *начало индекса для менеджеров не из десятки и не из тройки и где текущий юзер находится посередине
        let endRest = currentUserIndex + 1 + (showUsersNexToCurrentUserCount - 1) / 2; // *конец индекса
        if (endRest > end) {
          startRest = startRest + end - endRest;
          endRest = end;
        }
        const usersNextWithUserCurrent = data.slice(startRest, endRest);
        const itemForDot = {};
        ratingItems = [...usersFirst, itemForDot, ...usersNextWithUserCurrent];
      }
    } else {
      //* currentUserCode отсутствует в списке (текущий юзер руководитель), показываем топ showNumberUsers
      ratingItems = data.slice(0, showNumberUsers);
    }
  }

  const fieldRef = React.useRef(null);
  useEffect(() => {
    if (isScrollIntoView && fieldRef.current) {
      fieldRef.current.scrollIntoView({ behavior: 'smooth' });
      onShow && onShow();
    }
  }, [isShowAll]);
  if (!isLoaded) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {[...new Array(showNumberUsers)].map((_i, index) => (
          <ListItem key={index}>
            <Skeleton sx={{ transform: 'none' }} height={31} width={'100%'} />
          </ListItem>
        ))}
      </Box>
    );
  }
  if (ratingItems.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '160px' }}>
        <Typography variant="subtitle1" color="initial">
          Нет данных
        </Typography>
      </Box>
    );
  }
  const achievementItemFirstLength = String(Math.round(ratingItems[0]?.achievement ?? 0)).length;
  return (
    <List disablePadding sx={{ overflow: 'auto' }}>
      {ratingItems.map((rating, i) => {
        let text: string;
        if (rating.employee) {
          text = `${rating.ratingPosition}. ${rating.employee.surname}${rating.employee?.name ? ' ' + rating.employee.name : ''}`;
        } else {
          text = '...';
        }
        return (
          <ListItem key={i} {...(rating?.employee?.code === currentUserCode ? { ref: fieldRef } : {})}>
            <ListItemAvatar sx={{ maxHeight: '39px' }}>
              {rating.employee && (
                <UserAvatar surname={rating.employee?.surname} src={rating.employee?.avatarUrl} name={rating.employee?.name} />
              )}
            </ListItemAvatar>

            <ListItemText
              primary={text}
              primaryTypographyProps={{
                sx: {
                  fontWeight: rating?.employee?.code === currentUserCode ? '500' : '400',
                  marginRight:
                    rating?.ratingPosition < 4
                      ? `calc(24px + 24px + ${String(Math.round(achievementItemFirstLength ?? 0)).length * 9 + 4}px - ${
                          String(Math.round(rating?.achievement ?? 0)).length * 8 + 4
                        }px)`
                      : 0,
                },
              }}
            />
            <ListItemIcon
              sx={{
                minWidth: '24px',
                position: 'absolute',
                right: `calc(24px + ${achievementItemFirstLength * 9 + 4}px)`,
              }}
            >
              {rating?.ratingPosition < 4 && <StarsIcon sx={{ color: colorByRatingPosition[rating.ratingPosition] || 'text.secondary' }} />}
            </ListItemIcon>
            {text !== '...' && (
              <Typography
                sx={{ fontWeight: rating?.employee?.code === currentUserCode ? '500' : '400', textAlign: 'right' }}
                variant="body2"
              >
                {rating.achievement ? `${Math.round(rating.achievement)}%` : `0%`}
              </Typography>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};
