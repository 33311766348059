import React, { useState } from 'react';
import type { FC } from 'react';
import Tab from '@mui/material/Tab';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { StatMarginalityEmployeeOverallRatingItem } from 'src/api/marketx';
import { namesRatingTabs, RatingTabsType } from 'src/store/RatingOfManagersStore';
import { ListRating } from './ListRating';
import { SxProps } from '@mui/material/styles';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import { CustomPopper } from '../../../views/deals/components/CustomPopper';

interface SalesRatingProps {
  data: Record<RatingTabsType, StatMarginalityEmployeeOverallRatingItem[]>;
  userCode: string; //* Код юзера в сессии
  isLoaded: boolean;
  sx?: SxProps;
}

const SalesRating: FC<SalesRatingProps> = ({ data = {}, userCode: currentUserCode = '', isLoaded = true, sx, ...rest }) => {
  const [selectedTab, setSelectedTab] = useState('branch');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const anchorEl = React.useRef<HTMLButtonElement>(null);
  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: string): void => {
    setSelectedTab(newValue);
  };
  const handleDialogOpen = (): void => {
    setIsDialogOpen(!isDialogOpen);
  };
  const handleRatingSelect = (): void => {
    setIsDialogOpen(false);
  };
  return (
    <>
      <Card
        className="withCustomScroll"
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', overflowY: 'auto', ...sx }}
        {...rest}
      >
        <CardHeader
          sx={{ paddingTop: 1, paddingBottom: 0, paddingLeft: 1, paddingRight: 1 }}
          title={
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography fontWeight={400} variant="h6" component={'span'}>
                  Чемпионы
                </Typography>
              </Grid>
              <Grid item>
                <IconButton color="primary" ref={anchorEl} onClick={handleDialogOpen} component="span" size="small">
                  <MoreVertSharpIcon />
                </IconButton>
              </Grid>
            </Grid>
          }
        />
        <TabContext value={selectedTab}>
          <TabList onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="fullWidth">
            {Object.keys(namesRatingTabs).map(tab => (
              <Tab label={namesRatingTabs[tab]} value={tab} key={tab} />
            ))}
          </TabList>
          {Object.keys(namesRatingTabs).map(tab => (
            <TabPanel sx={{ padding: 0 }} value={tab} key={tab}>
              <ListRating data={data[tab]} isLoaded={isLoaded} isShowAll={true} currentUserCode={currentUserCode} />
            </TabPanel>
          ))}
        </TabContext>
      </Card>
      <CustomPopper
        isOpen={isDialogOpen}
        anchorEl={anchorEl.current}
        onClose={() => setIsDialogOpen(false)}
        placement="bottom-end"
        sx={{ minWidth: '200px' }}
      >
        <>
          <MenuItem onClick={handleRatingSelect} selected={true}>
            Чемпионы
          </MenuItem>
          <MenuItem onClick={handleRatingSelect}>Мастер сделки</MenuItem>
        </>
      </CustomPopper>
    </>
  );
};

export { SalesRating };
