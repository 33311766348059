import React, { FC, JSX, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { TasksStore } from '../../../store/TasksStore';
import Button from '@mui/material/Button';
import { useTaskDialogCreate } from '../../Clients/Hooks/useTaskDialogCreate';
import Typography from '@mui/material/Typography';
import { TaskDialog } from '../TaskDialog';
import { TaskItem } from '../../../api/marketx';
import { useWebSocketHandler } from '../../../hooks/useWebSocketHandler';
import { KanbanBoardShortedItem } from './KanbanBoardShortedItem';
import { Link as MxLink } from '@mx-ui/ui';
import { styled } from '@mui/material/styles';
import { EmployeesStore } from '../../../store/Employees/EmployeesStore';
import { FilterForTaskStore } from '../../../store/FilterForTaskStore';
import { useRootStore } from '../../../store/MobxStoreProvider';

const StyledLink = styled(MxLink)(() => ({
  textTransform: 'uppercase',
  fontSize: '13px',
  fontWeight: 500,
  padding: 8,
  '&:hover': { backgroundColor: 'rgba(26, 35, 126, 0.04)' },
}));

interface KanbanBoardShortedProps {
  filtersStr: string;
  isGridEditing: boolean;
}

export const KanbanBoardShorted: FC<KanbanBoardShortedProps> = observer(({ filtersStr, isGridEditing }): JSX.Element => {
  const rt = useRootStore();
  const [employeesStore] = useState(() => new EmployeesStore(rt));
  const [store] = useState(() => {
    const store = new TasksStore(rt);
    store.setRouterControl(true);
    store.setEmployeesStore(employeesStore);

    store.setFilterStore(new FilterForTaskStore(store));
    store.setRequestInit({ readyExpireDays: 3 });
    store.setRequestFromStr(filtersStr);

    store.loadTasks(store.request);

    store.loadedAllReferences();
    return store;
  });
  const [tasks, setTasks] = useState<Record<string, TaskItem[]>>({});
  useWebSocketHandler(store.handleWs);
  useEffect(() => {
    const initColumn = store.statuses.reduce<Record<string, any[]>>((acc, i) => {
      acc[i.code] = [];
      return acc;
    }, {});
    const listTasks = store.tasks.reduce<Record<string, TaskItem[]>>((acc, i) => {
      if (acc[i.status.code]) {
        acc[i.status.code].push(i);
      } else {
        acc[i.status.code] = [i];
      }
      return acc;
    }, initColumn);
    setTasks(listTasks);
  }, [store.statuses, store.tasks]);
  const { openTaskDialog, selectTask, handleSaveTask, handleCreateTask, handleCloseTaskDialog, handleClickTask } =
    useTaskDialogCreate(store);

  const todoTasks = tasks?.['todo'] || [];
  const inProgress = tasks?.['in_progress'] || [];

  return (
    <>
      <Paper
        className="withCustomScroll"
        sx={{ height: '100%', width: '100%', padding: 1, minHeight: 'calc(100% - 8px)', overflow: 'auto' }}
      >
        <Grid container height={'100%'} flexDirection={'column'} flexWrap={'nowrap'}>
          <Grid item container justifyContent={'space-between'} mb={1} height={'30px'}>
            <Button
              variant="contained"
              size="small"
              onClick={handleCreateTask}
              sx={{ ml: 1, height: '30px' }}
              disabled={!store.isLoaded || isGridEditing}
            >
              Создать
            </Button>
            {!isGridEditing && (
              <StyledLink href={'/app/tasks/desk'} color={'primary'}>
                ВСЕ ЗАДАЧИ &gt;
              </StyledLink>
            )}
          </Grid>
          <Grid item flex={'1 1 auto'}>
            <Grid container justifyContent={'space-between'} spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={12} xl={6}>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {todoTasks.length ? `В очереди (1 из ${todoTasks.length})` : 'В очереди'}
                </Typography>
                {todoTasks[0] ? (
                  <KanbanBoardShortedItem task={todoTasks[0]} onClickTask={v => !isGridEditing && handleClickTask(v)} />
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={12} xl={6}>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  {inProgress.length ? `В работе (1 из ${inProgress.length})` : 'В работе'}
                </Typography>
                {inProgress[0] ? (
                  <KanbanBoardShortedItem task={inProgress[0]} onClickTask={v => !isGridEditing && handleClickTask(v)} />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <TaskDialog open={openTaskDialog} onClose={handleCloseTaskDialog} defaultValues={selectTask} store={store} onSave={handleSaveTask} />
    </>
  );
});
