import React, { FC, JSX, useCallback, useEffect, useMemo, useState } from 'react';
import { ItemCallback, Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { observer } from 'mobx-react-lite';
import { styled, useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { DashboardSettings } from '../../components/Home/Dashboard/DashboardSettings';
import { useRootStore } from '../../store/MobxStoreProvider';
import { HomeGridStore } from '../../store/Home/HomeGridStore';
import { toJS } from 'mobx';
import { CountDoughnut } from '../../components/Home/Dashboard/CountChart';
import { PlanChart } from '../../components/Home/Dashboard/PlanChart';
import { ProfitChart } from '../../components/Home/Dashboard/ProfitChart';
import { WorkEvaluationChart } from '../../components/Home/Dashboard/WorkEvaluationChart';
import { HomeIndicatorsReport } from '../../components/Home/HomeIndicatorsReport ';
import { SalesRating } from '../../components/Home/SalesRating';
import { IndicatorsPanel } from '../../components/Home/IndicatorsPanel';
import { ReservesPanel } from '../../components/Home/ReservesPanel';
import { KanbanBoardShorted } from '../../components/Home/KanbanBoard/KanbanBoardShorted';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatMonth } from '@mx-ui/helpers';

const ResponsiveGridLayout = WidthProvider(Responsive);

const StyledContainer = styled(Container)(({ theme }) => {
  return {
    position: 'relative',
    minWidth: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  };
});
export const HomeGrid: FC<{ filtersStr: string }> = observer(({ filtersStr }): JSX.Element => {
  const date = useMemo(() => new Date(), []);
  const matchesXs = useMediaQuery('(min-width:464px)');
  const matchesMd = useMediaQuery('(min-width:600px)');
  const rt = useRootStore();
  const store = useMemo(() => {
    const homeStore = new HomeGridStore(rt);
    homeStore.employeesMarginality = rt.getEmployeesMarginality(async store => {
      store.setDate(new Date());
      store.initialLoadStats();
    });
    homeStore.ratingOfManagersStore = rt.getRatingOfManagersStore(async store => {
      store.initialLoad(homeStore.authStore.profile.chosenBranchOfficeCode || homeStore.authStore.profile.branchOfficeCode);
    });
    homeStore.getUserLayouts();
    return homeStore;
  }, [rt]);
  const theme = useTheme();

  const [breakpoint, setBreakpoint] = useState(!matchesXs ? 'xxs' : 'lg');

  const budgetGroupsStats = store.employeesMarginality.budgetGroupsStats.length
    ? store.employeesMarginality.budgetGroupsStats[0] || undefined
    : undefined;

  const onResize: ItemCallback = () => {};

  const onWidthChange = useCallback((...rest): void => {
    const [width] = rest;
    if (width > 1200) {
      setBreakpoint('lg');
    } else if (width > 996) {
      setBreakpoint('md');
    } else if (width > 768) {
      setBreakpoint('sm');
    } else if (width > 480) {
      setBreakpoint('xs');
    } else {
      setBreakpoint('xxs');
    }
  }, []);
  const onLayoutChange = (): void => {};

  const layout = useMemo(() => store.layouts?.[breakpoint] || [], [store, store.layouts, store.layouts?.[breakpoint], breakpoint]);

  const onChange: ItemCallback = layout => {
    store.onLayoutChange({ ...store.layouts, [breakpoint]: layout });
  };

  useEffect(() => {
    if (!layout.length) {
      window.scrollTo({ top: 0 });
    }
  }, [layout]);

  if (!store.layouts || !store.isLoaded) {
    return;
  }

  const components = {
    // donatCount - Количество
    '0': (
      <CountDoughnut
        budgetGroupsStats={budgetGroupsStats}
        employeesMarginality={store.employeesMarginality}
        isGridEditing={store.isEditing}
      />
    ),
    // donatPlan - План
    '1': (
      <PlanChart budgetGroupsStats={budgetGroupsStats} employeesMarginality={store.employeesMarginality} isGridEditing={store.isEditing} />
    ),
    // donatProfit - МаржДоход
    '2': (
      <ProfitChart
        budgetGroupsStats={budgetGroupsStats}
        employeesMarginality={store.employeesMarginality}
        isGridEditing={store.isEditing}
      />
    ),
    // donatWorkEvaluation - Оценка работы
    '3': (
      <WorkEvaluationChart
        budgetGroupsStats={budgetGroupsStats}
        employeesMarginality={store.employeesMarginality}
        isGridEditing={store.isEditing}
      />
    ),
    // salesFunnel - Воронка продаж
    '4': <HomeIndicatorsReport isGridEditing={store.isEditing} />,
    // champions - Чемпионы
    '5': (
      <SalesRating
        data={store.ratingOfManagersStore.rating}
        isLoaded={store.ratingOfManagersStore.isLoaded}
        userCode={store.authStore.profile.employee?.code}
      />
    ),
    // paymentSchedule - График платежей
    '6': <IndicatorsPanel isGridEditing={store.isEditing} />,
    // reserves - Резерв
    '7': <ReservesPanel isGridEditing={store.isEditing} />,
    // kanbanBoard - Доска задач
    '8': <KanbanBoardShorted filtersStr={filtersStr} isGridEditing={store.isEditing} />,
  };
  return (
    <StyledContainer sx={{ minHeight: matchesMd ? '100vh' : 'calc(100vh - 50px)' }}>
      <Grid justifyContent={'flex-start'} pl={2}>
        <Typography variant={'body2'} color={'text.secondary'}>
          {`Аналитика: ${formatMonth(date).slice(0, 1).toUpperCase() + formatMonth(date).slice(1)} ${date.getFullYear()} ${
            store.structureName
          }`}
        </Typography>
      </Grid>
      {!store.layouts || !layout?.length || !store.isLoaded ? (
        <Grid
          container
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          flexWrap={'nowrap'}
          sx={{ minHeight: 'calc(100vh - 138px)' }}
        >
          <Grid
            item
            sx={{
              width: { xs: '180px', sm: '230px', md: '280px' },
              height: { xs: '95px', sm: '120px', md: '150px' },
            }}
          >
            <img
              alt="MX"
              src={theme.palette.mode === 'dark' ? '/images/mx/lightMx.png' : `/images/mx/darkMx.png`}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid
            item
            sx={{
              height: { xs: '84px', sm: '105px', md: '135px' },
            }}
          >
            <Typography
              align="center"
              sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#080F72', fontSize: { xs: '23px', sm: '28px', md: '36px' } }}
            >
              Интеллектуальная
              <br />
              экосистема продаж
              <br />
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      <ResponsiveGridLayout
        compactType={'vertical'}
        isDraggable={store.isEditing} // для запрета изменения размера
        isResizable={store.isEditing} // для запрета изменения позиции
        layouts={toJS(store.layouts)}
        rowHeight={40}
        breakpoints={{ lg: 1420, md: 1200, sm: 710, xs: 480, xxs: 0 }}
        cols={{ lg: 24, md: 20, sm: 12, xs: 8, xxs: 4 }}
        onResizeStop={onChange}
        onResize={onResize}
        onDragStop={onChange}
        onWidthChange={onWidthChange}
        onLayoutChange={onLayoutChange}
        margin={[12, 12]}
        breakpoint={breakpoint}
        style={{
          minHeight: layout?.length ? 'calc(100vh - 96px)' : '100%',
        }}
      >
        {(layout || []).map(widget => (
          <Box
            data-grid={widget}
            key={widget.i}
            className={'item'}
            sx={{
              width: '100%',
              height: '100%',
              position: 'relative',
              zIndex: 0,
              '& .react-resizable-handle': {
                ':after': {
                  width: '10px',
                  height: '10px',
                  zIndex: 300,
                  borderRight: '2px solid #d32f2f',
                  borderBottom: '2px solid #d32f2f',
                },
              },
            }}
          >
            {components?.[widget.i] || ''}
          </Box>
        ))}
      </ResponsiveGridLayout>
      <DashboardSettings store={store} breakpoint={breakpoint} />
    </StyledContainer>
  );
});
