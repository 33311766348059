import React from 'react';
import DashboardLayout from 'src/components/DashboardLayout';
import Page from 'src/components/Page';
import { HomeGrid } from '../../../views/home/HomeGrid';

import mediaQuery from 'css-mediaquery';
import { useRouter } from 'next/router';
interface Query {
  readonly path?: string[];
  readonly filters?: string;
}
export const ssrMatchMedia = (query: string): { matches: boolean } => ({
  matches: mediaQuery.match(query, {
    width: 800,
  }),
});
const HomePage = (): JSX.Element => {
  const router = useRouter();
  const { filters }: Query = router.query;
  return (
    <DashboardLayout>
      <Page title="Главная">
        <HomeGrid filtersStr={filters} />
      </Page>
    </DashboardLayout>
  );
};

export default HomePage;
