import React, { FC, JSX, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Link as MxLink } from '@mx-ui/ui';
import { useRootStore } from '../../store/MobxStoreProvider';
import { ReservesAllowedParams, ReservesReportStore, transformQueryReservesToAllowedParams } from '../../store/ReservesReportStore';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { formatNumber3, formatPriceCur } from '@mx-ui/helpers';
import { dodMomHelper, IndicatorArrow } from '../report/reserves/ReserveReportWidget';

export const ReservesPanel: FC<{ isGridEditing: boolean }> = observer(({ isGridEditing }): JSX.Element => {
  const rootStore = useRootStore();
  const router = rootStore.getRouter();
  const reservesReportStore = useMemo(() => {
    const store = new ReservesReportStore(rootStore);
    store.setRouterControl(false);
    store.initialLoadStatsByQuery(transformQueryReservesToAllowedParams<ReservesAllowedParams>(router.query(), store.role), true);
    return store;
  }, [rootStore]);

  const isLoading = reservesReportStore.isLoading;
  const reportAll =
    reservesReportStore.statsByCode[reservesReportStore.selectedCrumbs[reservesReportStore.mode]?.code]?.rows?.filter(
      r => r.budgetGroupCode === 'all'
    )?.[0] || {};
  const indicators = reservesReportStore.indicators[reservesReportStore.mode];
  const dod = {
    quantityTne: dodMomHelper(indicators?.dodSelectedDay?.quantityTne, indicators?.dodPreviousDay?.quantityTne),
    maxReserveDays: dodMomHelper(indicators?.dodSelectedDay?.maxReserveDays, indicators?.dodPreviousDay?.maxReserveDays),
    avgReserveDays: dodMomHelper(indicators?.dodSelectedDay?.avgReserveDays, indicators?.dodPreviousDay?.avgReserveDays),
    purchaseTotalCost: dodMomHelper(indicators?.dodSelectedDay?.purchaseTotalCost, indicators?.dodPreviousDay?.purchaseTotalCost),
    mrcTotalCost: dodMomHelper(indicators?.dodSelectedDay?.mrcTotalCost, indicators?.dodPreviousDay?.mrcTotalCost),
  };
  const mom = {
    quantityTne: dodMomHelper(indicators?.momSelectedMonth?.quantityTne, indicators?.momPreviousMonth?.quantityTne),
    maxReserveDays: dodMomHelper(indicators?.momSelectedMonth?.maxReserveDays, indicators?.momPreviousMonth?.maxReserveDays),
    avgReserveDays: dodMomHelper(indicators?.momSelectedMonth?.avgReserveDays, indicators?.momPreviousMonth?.avgReserveDays),
    purchaseTotalCost: dodMomHelper(indicators?.momSelectedMonth?.purchaseTotalCost, indicators?.momPreviousMonth?.purchaseTotalCost),
    mrcTotalCost: dodMomHelper(indicators?.momSelectedMonth?.mrcTotalCost, indicators?.momPreviousMonth?.mrcTotalCost),
  };
  return (
    <Paper sx={{ height: '100%', width: '100%', padding: 1 }}>
      <Grid container flexDirection="column" flexWrap="nowrap" justifyContent="space-between" height="100%">
        <Grid item mb={1}>
          <MxLink
            href={isGridEditing ? undefined : `/app/report/reserves?mode=${reservesReportStore.mode}`}
            variant="h6"
            color="inherit"
            typographySx={{ cursor: isGridEditing ? 'initial' : 'pointer' }}
          >
            Резерв
          </MxLink>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                  <Typography variant={'body1'}>Вес, т</Typography>
                </Grid>
                <Grid item>
                  {isLoading ? (
                    <Grid height={'40px'}>
                      <Skeleton width={'100px'} />
                    </Grid>
                  ) : (
                    <Grid container alignItems={'center'}>
                      <Grid item mr={2}>
                        <Typography variant={'body1'}>{reportAll.quantityTne ? formatNumber3(reportAll.quantityTne) : '-'}</Typography>
                      </Grid>
                      <Grid item height={'40px'}>
                        {indicators && <IndicatorArrow dod={dod.quantityTne} mom={mom.quantityTne} />}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                  <Typography variant={'body1'}>Максимальный, дней</Typography>
                </Grid>
                <Grid item>
                  {isLoading ? (
                    <Grid height={'40px'}>
                      <Skeleton width={'100px'} />
                    </Grid>
                  ) : (
                    <Grid container alignItems={'center'}>
                      <Grid item mr={2}>
                        <Typography variant={'body1'}>{reportAll.maxReserveDays || '-'}</Typography>
                      </Grid>
                      <Grid item height={'40px'}>
                        {indicators && <IndicatorArrow dod={dod.maxReserveDays} mom={mom.maxReserveDays} />}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                  <Typography variant={'body1'}>Средний, дней</Typography>
                </Grid>
                <Grid item>
                  {isLoading ? (
                    <Grid height={'40px'}>
                      <Skeleton width={'100px'} />
                    </Grid>
                  ) : (
                    <Grid container alignItems={'center'}>
                      <Grid item mr={2}>
                        <Typography variant={'body1'}>
                          {' '}
                          {reportAll.reservesCount > 0 && reportAll.reserveDaysTotal > 0
                            ? Math.round(reportAll.reserveDaysTotal / reportAll.reservesCount)
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item height={'40px'}>
                        {indicators && <IndicatorArrow dod={dod.avgReserveDays} mom={mom.avgReserveDays} />}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                  <Typography variant={'body1'}>В ценах закупки</Typography>
                </Grid>
                <Grid item>
                  {isLoading ? (
                    <Grid height={'40px'}>
                      <Skeleton width={'100px'} />
                    </Grid>
                  ) : (
                    <Grid container alignItems={'center'}>
                      <Grid item mr={2}>
                        <Typography variant={'body1'}>
                          {reportAll.purchaseTotalCost ? formatPriceCur(reportAll.purchaseTotalCost) : '-'}
                        </Typography>
                      </Grid>
                      <Grid item height={'40px'}>
                        {indicators && <IndicatorArrow dod={dod.purchaseTotalCost} mom={mom.purchaseTotalCost} />}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                  <Typography variant={'body1'}>В МРЦ</Typography>
                </Grid>
                <Grid item>
                  {isLoading ? (
                    <Grid height={'40px'}>
                      <Skeleton width={'100px'} />
                    </Grid>
                  ) : (
                    <Grid container alignItems={'center'}>
                      <Grid item mr={2}>
                        <Typography variant={'body1'}>{reportAll.mrcTotalCost ? formatPriceCur(reportAll.mrcTotalCost) : '-'}</Typography>
                      </Grid>
                      <Grid item height={'40px'}>
                        {indicators && <IndicatorArrow dod={dod.mrcTotalCost} mom={mom.mrcTotalCost} />}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
});
