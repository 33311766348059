import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link as MxLink } from '@mx-ui/ui';
import Typography from '@mui/material/Typography';
import { DoughnutChart } from '../../charts/DoughnutChart';
import CircleIcon from '@mui/icons-material/Circle';
import { EmployeesMarginalityStore } from '../../../store/EmployeesMarginalityStore';
import { EmployeeMarginalityReport } from '../../../api/marketx';

interface PlanChartProps {
  employeesMarginality: EmployeesMarginalityStore;
  budgetGroupsStats: EmployeeMarginalityReport;
  isGridEditing: boolean;
}

export const PlanChart: FC<PlanChartProps> = observer(({ employeesMarginality, budgetGroupsStats: stats, isGridEditing }): JSX.Element => {
  const { report } = stats || {};
  const { overallForecast } = report || {};
  const overallAchievementPercent = Math.round(report?.overallAchievement ?? 0);
  const { level: overallForecastLevel, value: overallForecastValue } = overallForecast || {};
  return (
    <Paper sx={{ padding: 1, height: '100%', width: '100%' }}>
      <Box sx={{ textAlign: 'left' }}>
        <MxLink
          href={isGridEditing ? undefined : `/app/report/targets?${'mode=' + employeesMarginality.mode}`}
          variant="h6"
          color="inherit"
          typographySx={{ cursor: isGridEditing ? 'initial' : 'pointer' }}
        >
          План
        </MxLink>
      </Box>
      <Grid container alignItems="center" justifyContent="center" flexDirection="column">
        <Grid item sx={{ position: 'relative', margin: '0 auto', maxWidth: '120px', maxHeight: '120px', padding: 1 }}>
          <DoughnutChart value={!employeesMarginality.isLoading ? overallAchievementPercent : 0} />
        </Grid>
        <Grid item>
          <Grid container>
            {overallForecastLevel && <CircleIcon sx={{ color: overallForecastLevel }} />}
            <Typography sx={{ marginLeft: '4px' }} variant="body1" gutterBottom>
              Тренд {overallForecastValue ? Math.round(overallForecastValue) : 0}%
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
});
