import React, { FC, JSX, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import SettingsIcon from '@mui/icons-material/Settings';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { HomeGridStore, layoutsNames } from '../../../store/Home/HomeGridStore';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp';
import { DialogContainer } from '../../ui/dialogs/DialogContainer';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

const layoutsComponents: Record<string, { title: string; light: string; dark: string }> = {
  donatCount: {
    title: 'Количество',
    light: '/dashboardImg/doughnutChartLight.jpg',
    dark: '/dashboardImg/doughnutChartDark.jpg',
  },
  donatPlan: {
    title: 'План',
    light: '/dashboardImg/doughnutChartLight.jpg',
    dark: '/dashboardImg/doughnutChartDark.jpg',
  },
  donatProfit: {
    title: 'МаржДоход',
    light: '/dashboardImg/doughnutChartLight.jpg',
    dark: '/dashboardImg/doughnutChartDark.jpg',
  },
  donatWorkEvaluation: {
    title: 'Оценка работы',
    light: '/dashboardImg/donatWorkEvaluationLight.jpg',
    dark: '/dashboardImg/donatWorkEvaluationDark.jpg',
  },
  salesFunnel: {
    title: 'Воронка продаж',
    light: '/dashboardImg/salesFunnelLight.jpg',
    dark: '/dashboardImg/salesFunnelDark.jpg',
  },
  champions: {
    title: 'Чемпионы',
    light: '/dashboardImg/championsLight.jpg',
    dark: '/dashboardImg/championsDark.jpg',
  },
  paymentSchedule: {
    title: 'График платежей',
    light: '/dashboardImg/paymentScheduleLight.jpg',
    dark: '/dashboardImg/paymentScheduleDark.jpg',
  },
  reserves: {
    title: 'Резерв',
    light: '/dashboardImg/reservesLight.jpg',
    dark: '/dashboardImg/reservesDark.jpg',
  },
  kanbanBoard: {
    title: 'Доска задач',
    light: '/dashboardImg/kanbanBoardLight.jpg',
    dark: '/dashboardImg/kanbanBoardDark.jpg',
  },
};

interface DashboardSettingsProps {
  store: HomeGridStore;
  breakpoint: string;
}

export const DashboardSettings: FC<DashboardSettingsProps> = observer(({ store, breakpoint }): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  const handleClick = (): void => {
    if (isOpen) {
      setIsWidgetOpen(false);
    }
    setIsOpen(!isOpen);
    store.setEdit(!isOpen);
  };
  const handleWidgetClick = (): void => {
    setIsWidgetOpen(!isWidgetOpen);
  };

  const handleAddWidget = (widgetKey: string, breakpoint: string): void => {
    store.addWidget(widgetKey, breakpoint);
  };

  const handleAddAllWidgets = (breakpoint: string): void => {
    store.addAllWidgets(breakpoint);
  };

  const handleDeleteWidget = (widgetKey: string, breakpoint: string): void => {
    store.removeWidget(breakpoint, widgetKey);
  };

  const handleDeleteAllWidgets = (breakpoint: string): void => {
    store.removeAllWidgets(breakpoint);
  };

  const canAddAll = store.layouts?.[breakpoint].length === Object.keys(layoutsNames).length;
  const canRemoveAll = !store.layouts?.[breakpoint].length;
  const noAddedWidgetsValues = Object.keys(layoutsNames).map(i => ({ name: layoutsNames[i], key: i }));

  return (
    <>
      <Box onClick={handleClick} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 300 }}>
        <Fab color="primary" size="small" title={'Активировать настройку главной страницы'}>
          <SettingsIcon sx={{ color: '#fff' }} />
        </Fab>
      </Box>
      <Box
        onClick={handleWidgetClick}
        role="presentation"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          transition: 'all ease .4s',
          transform: isOpen ? 'translateX(-64px) scale(1)' : 'translateX(0) scale(0.3)',
          zIndex: 299,
        }}
      >
        <Fab color="primary" size="small" title={'Добавить виджет'}>
          <LibraryAddIcon sx={{ color: '#fff' }} />
        </Fab>
      </Box>
      <DialogContainer
        open={isWidgetOpen}
        onClose={handleWidgetClick}
        dialogContent={
          <Grid p={1}>
            <Grid container>
              <Grid item xs={12} mb={2}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button variant={'contained'} size={'small'} disabled={canAddAll} onClick={() => handleAddAllWidgets(breakpoint)}>
                      Добавить все
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant={'contained'} size={'small'} disabled={canRemoveAll} onClick={() => handleDeleteAllWidgets(breakpoint)}>
                      Убрать все
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems={'center'} justifyContent={'center'} spacing={2}>
                  {noAddedWidgetsValues?.map(item => (
                    <SettingsWidgetItem
                      key={item.name}
                      item={item}
                      breakpoint={breakpoint}
                      store={store}
                      handleAddWidget={handleAddWidget}
                      handleDeleteWidget={handleDeleteWidget}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        dialogTitle={'Виджеты'}
        maxWidth={'md'}
        canselBtnText={'Закрыть'}
        fullWidth
      />
    </>
  );
});

interface SettingsWidgetItemProps {
  store: HomeGridStore;
  item: { name: any; key: string };
  breakpoint: string;
  handleAddWidget: (widgetKey: string, breakpoint: string) => void;
  handleDeleteWidget: (widgetKey: string, breakpoint: string) => void;
}

export const SettingsWidgetItem: FC<SettingsWidgetItemProps> = observer(
  ({ store, item, breakpoint, handleAddWidget, handleDeleteWidget }): JSX.Element => {
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    const handleOpen = (): void => {
      setIsHovered(true);
    };
    const handleClose = (): void => {
      setIsHovered(false);
    };
    const isDelete = store.layouts?.[breakpoint]?.some(i => i.i === item.key);
    return (
      <Grid key={item.name} item flex={'0 0 30%'} minWidth={120} height={140} sx={{ cursor: 'pointer' }}>
        <Paper
          sx={{ p: 1, height: '100%' }}
          onClick={() => (isDelete ? handleDeleteWidget(item.key, breakpoint) : handleAddWidget(item.key, breakpoint))}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
        >
          <Grid container height={'100%'} position={'relative'}>
            <Grid item xs={12} height={'20px'} textAlign={'center'}>
              <Typography
                variant={'body2'}
                color={'text.primary'}
                fontWeight={500}
                sx={{ cursor: 'pointer', opacity: !isDelete ? 1 : 0.3 }}
              >
                {layoutsComponents[item.name].title}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ height: 'calc(100% - 20px)', opacity: !isDelete ? 1 : 0.3 }}>
              <img
                src={theme.palette.mode === 'dark' ? layoutsComponents[item.name].dark : layoutsComponents[item.name].light}
                width={'100%'}
                height={'100%'}
                alt={''}
                style={{ objectFit: 'contain' }}
              />
            </Grid>
            {isDelete ? (
              <Grid
                position={'absolute'}
                sx={{
                  p: 0,
                  height: '120px',
                  width: '100%',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  transition: 'all ease .3s',
                  opacity: isHovered ? 0.4 : 0.05,
                }}
              >
                <RemoveCircleOutlineSharpIcon sx={{ width: '100%', height: '100%', color: 'red' }} />
              </Grid>
            ) : (
              <Grid
                position={'absolute'}
                sx={{
                  p: 0,
                  height: '120px',
                  width: '100%',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  transition: 'all ease .3s',
                  opacity: isHovered ? 0.4 : 0.04,
                }}
              >
                <AddCircleOutlineSharpIcon sx={{ width: '100%', height: '100%', color: 'green' }} />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    );
  }
);
