import React, { FC, JSX, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TaskItem } from '../../../api/marketx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import LightTooltip from '../../ToolTip/LightTooltip';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { PrioritiesIcon } from './PrioritiesIcon';
import Paper from '@mui/material/Paper';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chip from '@mui/material/Chip';

interface KanbanBoardShortedItemProps {
  task: TaskItem;
  onClickTask: (v: string) => void;
}
const StyledChip = styled(Chip)`
  ${({ theme }) => `
  transition: ${theme.transitions.create(['color'], {
    duration: theme.transitions.duration.standard,
  })};
  `}
`;
export const KanbanBoardShortedItem: FC<KanbanBoardShortedItemProps> = observer(({ task, onClickTask }): JSX.Element => {
  const [smallMode, setSmallMode] = useState(false);
  const mayChangeColor = true;
  const [isCollapseExited, setIsCollapseExited] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const handleClickByChip = (e): void => {
    setIsCollapseExited(false);
    e.stopPropagation();
    setSmallMode(v => !v);
  };
  const tasksByClient = task?.entities?.filter(item => item.entityTypeCode === 'customer') || [];
  return (
    <Paper
      onClick={() => onClickTask(task.code)}
      sx={{
        p: 1,
        minHeight: '20px',
        marginBottom: '8px',
        userSelect: 'none',
        height: 'auto',
        minWidth: 'auto',
        margin: '0',
      }}
    >
      <Box display={'flex'} flexDirection="column" flexWrap={'nowrap'} height={'100%'}>
        <Box mb={'10px'}>
          <Typography
            variant="body2"
            title={task.title}
            sx={{
              lineHeight: '20px',
              fontWeight: 400,
              overflow: 'hidden',
              maxHeight: '20px',
            }}
          >
            {task.title}
          </Typography>
        </Box>
        {tasksByClient?.length
          ? tasksByClient.map(customer => {
              return (
                <Box mb={'10px'} key={customer.code}>
                  <Typography
                    variant="body2"
                    title={task.title}
                    sx={{
                      lineHeight: '20px',
                      fontWeight: 400,
                      overflow: 'hidden',
                      maxHeight: '60px',
                    }}
                  >
                    {customer.entityTitle}
                  </Typography>
                </Box>
              );
            })
          : null}
        {!!task.labels?.length && (
          <Box display={'flex'} flexWrap="wrap" mb={'10px'}>
            {task.labels.map((option, index) => (
              <Collapse
                orientation="horizontal"
                in={!smallMode}
                sx={{
                  '& .MuiCollapse-wrapperInner': {
                    maxWidth: '100%',
                  },
                }}
                collapsedSize={20}
                key={index}
                onExited={() => setIsCollapseExited(true)}
                onEntered={() => setIsCollapseExited(false)}
              >
                <StyledChip
                  label={option.title}
                  onClick={handleClickByChip}
                  clickable={false}
                  sx={{
                    color: !smallMode ? option.textColor : 'transparent',
                    width: isCollapseExited ? '16px' : 'auto',
                    cursor: 'pointer',
                    bgcolor: option.color,
                    height: '16px',
                    margin: '2px',
                  }}
                  size="small"
                  title={option.title}
                />
              </Collapse>
            ))}
          </Box>
        )}
        <Box display={'flex'} justifyContent="space-between" maxWidth={'100%'}>
          <Box display={'flex'} alignItems="center">
            {!matches ? (
              <LightTooltip
                arrow
                placement="bottom"
                title={
                  <Box p={0.5}>
                    {task.deadlineAt && (
                      <Typography variant="caption">{task.deadlineAt ? formatDateSwaggerZ(task.deadlineAt) : '-'}</Typography>
                    )}
                  </Box>
                }
              >
                <Box display={'flex'} fontSize={'16px'}>
                  <AccessTimeIcon fontSize="inherit" style={{ marginTop: '1.5px' }} />
                </Box>
              </LightTooltip>
            ) : (
              <Box display={'flex'} fontSize={'16px'}>
                <AccessTimeIcon
                  fontSize="inherit"
                  style={{ marginTop: '1.5px' }}
                  htmlColor={mayChangeColor && new Date(task.deadlineAt).getTime() < new Date().getTime() ? 'red' : undefined}
                />
                <Typography variant="body2" fontSize={'14px'} component="div" noWrap>
                  {task.deadlineAt ? formatDateSwaggerZ(task.deadlineAt) : '-'}
                </Typography>
              </Box>
            )}

            <PrioritiesIcon priority={task.priority} />
          </Box>
          <Box maxWidth={'100%'} title={task.code} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Typography noWrap variant="caption">
              {task.code}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
});
